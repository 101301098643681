export const addComma = {
    methods: {
        addComma(num : String) {
            const regexp = /\B(?=(\d{3})+(?!\d))/g;
            return num.toString().replace(regexp, ",");
        }
    },
}


