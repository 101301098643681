export const getSecondCategoryWithSelection = {
    methods : {
        getSecondCategoryWithSelection : function(cateData : any, firstCate: any, secondCate: any) {
            const cateDataCopied = cateData.filter((ele:any) => ele.label === firstCate)[0];

            if (secondCate !== null) {
                for (const group of cateDataCopied.groups) {
                    if (group.label === secondCate) {
                        group.selected = true;
                    }
                }
            }
            return cateDataCopied;
        }
    },
}