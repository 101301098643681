<template>
  <div style="">
    <v-container style="max-width: none">
      <v-row>
        <v-col cols="4" style="padding: 0">
          <v-col cols="12">
            <v-card
              outlined
              tile
              id="category2"
              style="padding: 6px; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);"
            >
              <BarTable
                ref="HeightValueToBarTable"
              ></BarTable>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="8" style="padding: 0">
          <v-col cols="12">
            <v-card
              outlined
              tile
              style="box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);"
            >
              <Datatables ref="HeightValueToDatatables"></Datatables>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Datatables from "../../../components/explore_content/second_page/Datatable";
import BarTable from "@/components/explore_content/second_page/BarTable";
import EventBus from "../../../EventBus";

export default {
  name: "firstCate",
  components: {
    BarTable,
    Datatables
  },
  props: {
    selected: String
  },

  data() {
    return {
      SelectedSecondCateWord: null
    };
  },

  created() {

    window.onresize = () => {
      this.$refs.HeightValueToBarTable.reSizingHeight(window.innerHeight);
      this.$refs.HeightValueToDatatables.reSizingHeight(window.innerHeight);
    };
  },
  methods : {

  },
  beforeCreate() {
    if ((!this.$route.query.dataSet ||
      !this.$route.query.firstCate ||
      !this.$route.query.secondCate ||
      !this.$route.query.section) || (this.$route.query.firstCate === "선택한 카테고리") || (this.$route.query.secondCate === "선택한 카테고리")
    ) {
      this.$router.push({ name: "Index" }).catch(err => {});
    }
  }
};
</script>
<style scoped>
#category2 {
}
</style>
