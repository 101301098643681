<template>
  <div style="padding: 5px">
    <h3
        style="font-family: 'Nanum Gothic Coding', monospace; margin: 15px; color: #000000"
    >
      <v-icon style="margin-right: 15px; font-size: 30px"
      >mdi-transition
      </v-icon>
      3차 카테고리 전체
    </h3>
    <v-divider style="margin: 15px"></v-divider>
    <div style="margin: 10px; font-size: 10px;font-weight: 200 ">
      ※
      <span style="color: darkred; font-weight: bold">연관어</span>를 클릭하여
      3차 카테고리를 선택해 주세요.
    </div>
    <div
        style="width:100%; overflow:auto"
        :style="{ height: datatablesHeight + 'px' }"
    >
      <table
          class="styled-table"
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
      >
        <thead>
        <tr>
          <th
              style="font-size: 1.0vw;width: 1px; height: 45px; text-align: center; background-color: #3c4a64; color: white; "
          >
            순위
          </th>
          <th
              style="font-size: 1.0vw; width: 1px; text-align: center; padding: 5px 0px;"
          >
            온톨로지
          </th>
          <th style="font-size: 1.0vw;">
            연관어
          </th>
          <th style="font-size: 1.0vw;">
              <span class="mouseover-cursor-pointer" @click="resetData()">
                카테고리
                <span
                    class="mouseover-to-darkgray"
                    style="font-size: 10px; font-weight: bold; padding: 0 5px; margin-left: 5px; color: black; background-color: whitesmoke; padding: 1px 5px 0px 3px; margin-right: 10px;border-radius: 5px; display: inline-block; "
                >
                  <v-icon style="color: black">mdi-backup-restore</v-icon>RESET
                </span>
              </span>
          </th>
        </tr>
        </thead>
        <tbody class="tableTbody">
        <tr
            v-for="(data, n) in filteredData"
            :key="n"
            style="height: 51px;"
            :style="{
              'background-color':
                data.word === selectedThirdCate ? '#000000' + 20 : 'white'
            }"
            :id="`datetables-${data.word}`"
        >
          <td style="">
            <div>{{ n + 1 }}</div>
          </td>

          <!--            온톨로지-->
          <td>
            <div
                v-if="originData"
                class="word"
                :id="data.word"
                v-on:click="pushToThirdCate()"
                @mouseover="mouseHoverTableRow(data.word)"
                label
                style="cursor: pointer; height: 39px;border-radius: 5px;display: inline-block;padding: 5px 10px; font-size: 18px;  color: white"
                te
                :style="{ 'background-color': hexToHSL(cateColor) }"
            >
              {{ data.word }}
            </div>
            <v-skeleton-loader
                v-else
                class="skeleton-loader"
                type="list-item"
            ></v-skeleton-loader>
          </td>
          <!--            온톨로지 end-->

          <!--            2차 연관어-->
          <td v-if="originData" style="text-align: left;">
              <span
                  class="word"
                  v-for="(word, n) in splitPhrases(
                  data.word + ',' + data.phrases
                ).slice(0, 4)"
                  :key="n"
                  :id="data.word"
                  v-on:click="pushToThirdCate()"
                  @mouseover="mouseHoverTableRow(word)"
                  style="font-size: 16px; cursor: pointer; margin-right: 4px"
              >{{ word }}
              </span>
          </td>
          <td v-else style="text-align: left;">
            <v-skeleton-loader
                class="skeleton-loader"
                type="list-item"
            ></v-skeleton-loader>
          </td>
          <!--            2차 연관어 end-->

          <td v-if="originData" style="text-align: left;">
              <span
                  class="word"
                  style="font-size: 14px; cursor: pointer; margin-right: 4px; color: darkgrey"
                  @click="
                  clickCategory(
                    data.word,
                    data.section_representative_name,
                    data.category_representative_name
                  )
                "
              >{{
                  `${
                      data.section_representative_name
                          ? data.section_representative_name + " /"
                          : ""
                  } ${
                      data.category_representative_name
                          ? data.category_representative_name
                          : ""
                  }`
                }}

              </span>
            <span
                v-if="data.section_representative_name"
                @click="
                  reloadSecondThirdCategory(
                    $store.getters.getSelectedDataSet,
                    data.section_representative_name,
                    data.category_representative_name,
                    data.word
                  )
                "
                style="padding:2px 2px 3px 2px;border: 1px solid darkgrey; border-radius: 5px; cursor: pointer;"
            >
                <v-icon id="link-variant">mdi-swap-horizontal</v-icon>
              </span>
          </td>
          <td v-else style="text-align: left;">
            <v-skeleton-loader
                class="skeleton-loader"
                type="list-item"
            ></v-skeleton-loader>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EventBus from "../../../EventBus";
import axios from "axios";
import {dataSetCodeConverter} from "../../../mixins/dataSetCodeConverter";
import {heightSize} from "../../../mixins/explore_period/heightSize";
import {moveScroll} from "../../../mixins/explore_period/moveScroll";

export default {
  mixins: [dataSetCodeConverter, moveScroll],

  data() {
    return {
      dataSet: this.$route.query.dataSet,
      section: this.$route.query.section,
      firstCate: this.$route.query.firstCate,
      secondCate: this.$route.query.secondCate,
      thirdCate: null,
      selectedThirdCate: null,
      originData: null,
      filteredData: null,
      headers: [
        {text: "단어", value: "word", width: "20%"},
        {text: "빈도", value: "freq", width: "10%"},
        {text: "연관어", value: "phrases"}
      ],
      datatablesHeight: window.innerHeight - heightSize.data().heightSize,
      cateColor: "red",
    };
  },

  created() {
    // PeriodTab.vue 에서 온 이벤트 등록
    EventBus.$on(
        "updateExploreContentDataTables",
        async (firstCate, secondCate, filterCate) => {
        }
    );
    EventBus.$on("sendCateColor", CateColor => {
      this.cateColor = CateColor;
    });
  },
  async mounted() {
    await this.getData(this.$route.query.firstCate, this.$route.query.secondCate);
    if (this.$route.query.grouping) {
      this.filterCategory(this.$route.query.grouping);
    }
  },
  methods: {
    splitPhrases: function (phrases) {
      const words = phrases.split(",")[0] === "" ? [] : phrases.split(",");
      // 단어가 한개만 존재할 때 ['단어', ''] 이 처럼 -1 인덱스에 빈 자리가 생김
      return words[words.length - 1] === ""
          ? words.slice(0, words.length - 1)
          : words;
    },
    mouseHoverTableRow: function (row) {
      this.thirdCate = row;
      EventBus.$emit("updateExploreStepBarThirdCate", row);
    },
    getData: async function (firstCate, secondCate) {
      this.originData = null;
      this.firstCate = firstCate;
      this.secondCate = secondCate;

      const dataSetCodeEn = this.krToEn(this.$route.query.dataSet);
      const requestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/getTwoDepthWordInfo?category=${firstCate}&word=${secondCate}&dataSet=${dataSetCodeEn}`;

      await axios
          .get(requestUrl, {
            headers: {Authorization: this.$store.getters.getLoginToken}
          })
          .then(res => {
            this.originData = res.data.two_depth_words;
            this.filteredData = this.originData.filter(data => data.hidden === 0);
          });
    },
    pushToThirdCate: function () {
      // 뒤로가기 눌렀을 때 마지막으로 클릭했던 단어 유지
      this.$router
          .push({
            query: {
              dataSet: this.dataSet,
              section: this.$route.query.section,
              firstCate: this.firstCate,
              secondCate: this.secondCate
            }
          })
          .catch(err => {
          });

      this.$router.push({
        name: "ExploreContentSelectFourthCategory",
        query: {
          dataSet: this.dataSet,
          section: this.section,
          firstCate: this.firstCate,
          secondCate: this.secondCate,
          thirdCate: this.thirdCate
        }
      });
    },
    reSizingHeight: function (innerHeight) {
      this.datatablesHeight = innerHeight - heightSize;
    },
    hexToHSL: function (H) {
      // Convert hex to RGB first
      let r = 0,
          g = 0,
          b = 0;
      if (H.length == 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length == 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
          cmax = Math.max(r, g, b),
          delta = cmax - cmin,
          h = 0,
          s = 0,
          l = 0;

      if (delta == 0) h = 0;
      else if (cmax == r) h = ((g - b) / delta) % 6;
      else if (cmax == g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0) h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1) - 10;

      return "hsl(" + h + "deg " + s + "% " + l + "% / 75%)";
    },
    changeMode: function (mode) {
      this.mode = mode;
    },
    sumAllWordLengthInCategory: function (iterable) {
      return iterable.categories
          .map(item => item.rwords.length)
          .reduce((prev, curr) => prev + curr, 0);
    },
    clickCategory: async function (selectedWord, section, category) {
      this.selectedThirdCate = selectedWord;
      await this.filterCategory(category);
      this.moveScroll(this.$el, `datetables-${selectedWord}`);
    },
    filterCategory: function (category) {
      const result = this.originData
          // .filter(item => item.section_representative_name === section)
          .filter(item => item.category_representative_name === category);
      // 카테고리를 클릭하면 객체가 같은지 확인 후 토글 처럼 사용
      JSON.stringify(this.filteredData) === JSON.stringify(result)
          ? this.resetData()
          : (this.filteredData = result);
    },
    resetData: function () {
      this.filteredData = this.originData.filter(data => data.hidden === 0);
    },
    reloadSecondThirdCategory: async function (
        dataSet,
        section,
        firstCate,
        secondCate
    ) {
      this.selectedThirdCate = this.$route.query.secondCate
      this.$router.push({
        query: {
          dataSet: dataSet,
          section: section,
          firstCate: firstCate,
          secondCate: secondCate,
          grouping: this.$route.query.firstCate
        },
        params: {}
      });
    }
  },
  beforeDestroy() {
  },
  watch: {
    async $route(to, from) {
      await this.getData(this.$route.query.firstCate, this.$route.query.secondCate);
      EventBus.$emit("updateExploreStepBarFirstCate", this.$route.query.firstCate);
      if (this.$route.query.grouping) {
        await this.filterCategory(this.$route.query.grouping);
      }
      this.moveScroll(this.$el, `datetables-${this.selectedThirdCate}`);
    }
  }
};
</script>

<style scoped>
.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  white-space: nowrap;
  overflow-y: scroll;
}

thead tr {
  background-color: #3c4a64;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 5px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
  transition-duration: 0.3s;
}

/*.styled-table tbody tr:last-of-type {*/
/*  border-bottom: 2px solid #009879;*/
/*}*/

.word:hover {
  background-color: yellow;
  transition-duration: 0.3s;
}

table tbody tr:hover {
  background-color: #eeeeee !important;
  transition-duration: 0.3s;
}

.mouseover-cursor-pointer {
  cursor: pointer !important;
}

.mouseover-to-darkgray:hover {
  background-color: darkgrey !important;
}

#link-variant:hover {
  transform: rotate(180deg);
}
</style>
