export const moveScroll = {
    methods : {
        moveScroll: function(el: any ,tagId : String) {
            const scrollOption = {}
            const barElementId = el.querySelector("#" + tagId);
            // barElementId.scrollTo(0, 0)
            if (barElementId === null) {
                return;
            } else if (barElementId.previousSibling !== null && barElementId.previousSibling.previousSibling === null) {
                barElementId.previousSibling.scrollIntoView(scrollOption);
            } else if (barElementId.previousSibling !== null) {
                barElementId.previousSibling.previousSibling.scrollIntoView(scrollOption);
             } else {
                barElementId.scrollIntoView(scrollOption);
            }

            // } else if (tagId.includes('datetables')) {
            //     barElementId.scrollIntoView(scrollOption);
            // }
        },
    }
}