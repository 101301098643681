var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"5px"}},[_c('h3',{staticStyle:{"font-family":"'Nanum Gothic Coding', monospace","margin":"15px","color":"#000000"}},[_c('v-icon',{staticStyle:{"margin-right":"15px","font-size":"30px"}},[_vm._v("mdi-transition ")]),_vm._v(" 3차 카테고리 전체 ")],1),_c('v-divider',{staticStyle:{"margin":"15px"}}),_vm._m(0),_c('div',{staticStyle:{"width":"100%","overflow":"auto"},style:({ height: _vm.datatablesHeight + 'px' })},[_c('table',{staticClass:"styled-table",attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"font-size":"1.0vw","width":"1px","height":"45px","text-align":"center","background-color":"#3c4a64","color":"white"}},[_vm._v(" 순위 ")]),_c('th',{staticStyle:{"font-size":"1.0vw","width":"1px","text-align":"center","padding":"5px 0px"}},[_vm._v(" 온톨로지 ")]),_c('th',{staticStyle:{"font-size":"1.0vw"}},[_vm._v(" 연관어 ")]),_c('th',{staticStyle:{"font-size":"1.0vw"}},[_c('span',{staticClass:"mouseover-cursor-pointer",on:{"click":function($event){return _vm.resetData()}}},[_vm._v(" 카테고리 "),_c('span',{staticClass:"mouseover-to-darkgray",staticStyle:{"font-size":"10px","font-weight":"bold","padding":"1px 5px 0px 3px","margin-left":"5px","color":"black","background-color":"whitesmoke","margin-right":"10px","border-radius":"5px","display":"inline-block"}},[_c('v-icon',{staticStyle:{"color":"black"}},[_vm._v("mdi-backup-restore")]),_vm._v("RESET ")],1)])])])]),_c('tbody',{staticClass:"tableTbody"},_vm._l((_vm.filteredData),function(data,n){return _c('tr',{key:n,staticStyle:{"height":"51px"},style:({
            'background-color':
              data.word === _vm.selectedThirdCate ? '#000000' + 20 : 'white'
          }),attrs:{"id":`datetables-${data.word}`}},[_c('td',{},[_c('div',[_vm._v(_vm._s(n + 1))])]),_c('td',[(_vm.originData)?_c('div',{staticClass:"word",staticStyle:{"cursor":"pointer","height":"39px","border-radius":"5px","display":"inline-block","padding":"5px 10px","font-size":"18px","color":"white"},style:({ 'background-color': _vm.hexToHSL(_vm.cateColor) }),attrs:{"id":data.word,"label":"","te":""},on:{"click":function($event){return _vm.pushToThirdCate()},"mouseover":function($event){return _vm.mouseHoverTableRow(data.word)}}},[_vm._v(" "+_vm._s(data.word)+" ")]):_c('v-skeleton-loader',{staticClass:"skeleton-loader",attrs:{"type":"list-item"}})],1),(_vm.originData)?_c('td',{staticStyle:{"text-align":"left"}},_vm._l((_vm.splitPhrases(
                data.word + ',' + data.phrases
              ).slice(0, 4)),function(word,n){return _c('span',{key:n,staticClass:"word",staticStyle:{"font-size":"16px","cursor":"pointer","margin-right":"4px"},attrs:{"id":data.word},on:{"click":function($event){return _vm.pushToThirdCate()},"mouseover":function($event){return _vm.mouseHoverTableRow(word)}}},[_vm._v(_vm._s(word)+" ")])}),0):_c('td',{staticStyle:{"text-align":"left"}},[_c('v-skeleton-loader',{staticClass:"skeleton-loader",attrs:{"type":"list-item"}})],1),(_vm.originData)?_c('td',{staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"word",staticStyle:{"font-size":"14px","cursor":"pointer","margin-right":"4px","color":"darkgrey"},on:{"click":function($event){return _vm.clickCategory(
                  data.word,
                  data.section_representative_name,
                  data.category_representative_name
                )}}},[_vm._v(_vm._s(`${ data.section_representative_name ? data.section_representative_name + " /" : "" } ${ data.category_representative_name ? data.category_representative_name : "" }`)+" ")]),(data.section_representative_name)?_c('span',{staticStyle:{"padding":"2px 2px 3px 2px","border":"1px solid darkgrey","border-radius":"5px","cursor":"pointer"},on:{"click":function($event){return _vm.reloadSecondThirdCategory(
                  _vm.$store.getters.getSelectedDataSet,
                  data.section_representative_name,
                  data.category_representative_name,
                  data.word
                )}}},[_c('v-icon',{attrs:{"id":"link-variant"}},[_vm._v("mdi-swap-horizontal")])],1):_vm._e()]):_c('td',{staticStyle:{"text-align":"left"}},[_c('v-skeleton-loader',{staticClass:"skeleton-loader",attrs:{"type":"list-item"}})],1)])}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"10px","font-size":"10px","font-weight":"200"}},[_vm._v(" ※ "),_c('span',{staticStyle:{"color":"darkred","font-weight":"bold"}},[_vm._v("연관어")]),_vm._v("를 클릭하여 3차 카테고리를 선택해 주세요. ")])
}]

export { render, staticRenderFns }