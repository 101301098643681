<template>
  <div>
    <h3
      style="font-family: 'Nanum Gothic Coding', monospace; margin: 15px; color: #000000"
    >
      <v-icon style="margin-right: 15px; font-size: 30px; "
        >mdi-transition-masked</v-icon
      >2차 카테고리 전체
    </h3>
    <v-divider style="margin: 15px"></v-divider>
    <div style="margin: 10px; font-size: 10px; font-weight: 200">
      ※
      <span style="color: darkred; font-weight: bold">아래 차트에서 </span
      >원하는 카테고리를
      <span style="color: darkred; font-weight: bold">클릭</span>해 주세요.
    </div>
    <div :style="{ height: `${barTableHeight}px` }" style="overflow: auto">
      <table style="width: 100%">
        <thead style="">
          <tr style="">
            <th
              style="width:57px; padding: 0; height: 45px; font-size: 1.0vw; text-align: center; background-color: #3c4a64; color: white;"
            >
              순위
            </th>
            <th
              style="height: 45px; font-size: 1.0vw; padding: 0; text-align: center; background-color: #3c4a64; color: white;"
            >
              온톨로지
            </th>
            <th
              style="height: 45px; width: 60%; font-size: 1.0vw;    text-align: left;background-color: #3c4a64;color: white;"
            >
              빈도
            </th>
          </tr>
        </thead>
        <tbody id="tableTbody">
          <tr
            v-for="(item, n) in cateData.groups"
            :key="n"
            :id="`bar-table-${item.label}`"
            class="text-center"
            v-on:click="clickBarRow(item.label)"
            style="transition-duration: 0.3s; cursor: pointer;border-bottom: 1px solid #80808030;"
            :style="{
              'background-color':
                item.label === secondCate ? cateColor + 40 : 'white'
            }"
          >
            <td style="font-size: 18px">{{ n + 1 }}</td>

            <td
              style="width: 100%; margin: 5px auto; border-radius: 5px; padding: 0; font-weight: bolder; display: table; height: 40px; "
              :style="{
                background: 'radial-gradient( white, ' + cateColor + ')'
              }"
            >
              <div
                class=""
                style="color: black; font-weight: 400; display: table-cell;
    vertical-align: middle; font-weight: bold;
"
                :style="{
                  'font-size': 18 + 'px'
                }"
              >
                {{ item.label }}
              </div>
            </td>
            <td style=" border-bottom: none; padding: 0 10px 18px 0;">
              <div class="outer-box">
                <div
                  class="inner-box"
                  :style="{
                    width: (item.weight / maxWeight) * 100 + '%',
                    backgroundColor: cateColor + '90',
                    background:
                      'linear-gradient(  45deg,#fafafa, ' + cateColor + ')'
                    // 'linear-gradient(  45deg,#fafafa,#d5d5d5  )'
                  }"
                >
                  <div
                    style="display: inline-block;padding: 8px;text-align: left;overflow: hidden; text-overflow: clip; }"
                  >
                    {{ addComma(item.weight) }}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import EventBus from "@/EventBus";
import { addComma } from "@/mixins/addComma";
import { heightSize } from "../../../mixins/explore_period/heightSize";
import { moveScroll } from "../../../mixins/explore_period/moveScroll";
import { getSecondCategoryWithSelection } from "@/mixins/getSecondCategoryWithSelection";

export default {
  props: {
    CardDynamicHeight: Number
  },
  mixins: [addComma, heightSize, moveScroll, getSecondCategoryWithSelection],

  data() {
    return {
      cateData: null,
      secondCate: this.$route.query.secondCate,
      cateColor: null,
      barTableHeight: window.innerHeight - heightSize.data().heightSize
    };
  },
  computed: {
    maxWeight() {
      return this.cateData.groups[0].weight;
    }
  },
  async created() {
    EventBus.$on("reloadSecondCategoryData", async () => {
      await this.createBarTable();
    });
    this.createBarTable();
  },
  methods: {
    clickBarRow: function(secondCate) {
      this.$router
        .push({
          query: {
            dataSet: this.$route.query.dataSet,
            section: this.$route.query.section,
            firstCate: this.$route.query.firstCate,
            secondCate: secondCate
          }
        })
    },
    reSizingHeight: function(innerHeight) {
      this.barTableHeight = innerHeight - this.heightSize;
    },
    filterCateData: function() {
      this.cateData = this.getSecondCategoryWithSelection(
        this.$store.getters.getCategoryData,
        this.$route.query.firstCate,
        this.$route.query.secondCate
      );
    },
    createBarTable: async function() {
      await this.filterCateData();
      this.cateColor = await this.cateData.groups[0].color;
      await EventBus.$emit("sendCateColor", this.cateColor);
      this.moveScroll(this.$el, `bar-table-${this.$route.query.secondCate}`);
    }
  },
  watch: {
    async $route(to, from) {
      this.firstCate = this.$route.query.firstCate;
      this.secondCate = this.$route.query.secondCate;
      await this.createBarTable();
      EventBus.$emit(
              "updateExploreStepBarSecondCate",
              this.$route.query.firstCate,
              this.secondCate
      );
    }
  }
};
</script>

<style scoped>
.outer-box {
  width: 90%;
  margin: auto;
  height: 8px;
  border-left: 1px solid #d6d6d6;
}

.inner-box {
  height: 100%;
  width: 0%;
  font-weight: bolder;
  vertical-align: bottom;
}
table tbody tr:hover {
  background-color: #eeeeee !important;
  transition-duration: 0.3s;
}
</style>
